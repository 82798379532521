import {Circle, CircleCheck} from "akar-icons";
import {format} from "date-fns/format";
import {DocumentStatus} from "../../../../models/documents/DocumentViews";

function DocumentCardHistoryTabHeader(
    {
        tabStatusName,
        statusDate,
        initiatorFio,
        isActive = true,
    }: Readonly<{
        tabStatusName: string,
        statusDate?: Date,
        initiatorFio?: string,
        isActive?: boolean,
    }>
) {

    let statusName = tabStatusName
    // extra case when UI status name do not equal backend status name
    if (statusName === DocumentStatus.ISSUED) {
        statusName = 'Issued'
    }

    const isCompletedStatus = statusDate && initiatorFio
    const style = isActive ? '' : 'unreached'

    const icon = isCompletedStatus
        ? <CircleCheck strokeWidth={1} size={40}
                       className={`document-card_data-step_status-name-icon ${style}`}/>
        : <Circle strokeWidth={1} size={40}
                  className={`document-card_data-step_status-name-icon ${style}`}/>

    const statusDateBlock = isCompletedStatus
        ? <div
            className={`document-card_data-step_status-date ${style}`}>{format(statusDate, "dd MMM yyyy 'at' hh:mm")}</div>
        : <></>

    const initiatorBlock = isCompletedStatus
        ? <div className={`document-card_data-step_initiator${style}`}>
            <div className={`document-card_data-step_initiator-title${style}`}>Initiator</div>
            <div
                className={`document-card_data-step_initiator-value${style}`}>{initiatorFio}</div>
        </div>
        : <></>

    return (
        <div className={"document-card_data-step_header"}>
            <div className={"document-card_data-step_status"}>
                <div className={"document-card_data-step_status-name"}>
                    {icon}
                    <div className={`document-card_data-step_status-name-text ${style}`}>{statusName}</div>
                </div>
                {statusDateBlock}
            </div>
            {initiatorBlock}
        </div>
    );
}

export default DocumentCardHistoryTabHeader;

