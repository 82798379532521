import DocumentCardHistoryTabActiveWithTextArea from "../history/DocumentCardHistoryTabActiveWithTextArea";
import {DocumentDetailed} from "../../../../models/documents/Documents";
import {returnItemsDocument} from "../../../../services/documents/DocumentsService";

function DocumentLoanCardActiveTabWithText(
    {doc}: Readonly<{ doc: DocumentDetailed }>
) {

    let enteredText = ''
    const handleFormChange = (textAreaData: string) => {
        enteredText = textAreaData
    }

    const returnItems = async () => {
        const docAsRequest = {...doc}
        docAsRequest.conclusion = enteredText
        await returnItemsDocument(docAsRequest)
    };

    const buttons = [
        {
            buttonName: 'Confirm return',
            buttonAction: returnItems,
            buttonClasses: 'btn_default btn-medium'
        },
    ]

    return (
        <DocumentCardHistoryTabActiveWithTextArea
            statusTitle="Completed"
            title="Order report"
            buttons={buttons}
            placeholderTest="Please provide feedback"
            callBackForm={handleFormChange}/>
    );
}

export default DocumentLoanCardActiveTabWithText;

